import { ProductVariationWithProductResponse } from "@/web-client/api";
import CartBtnWrapper from "@/components/CartBtnWrapper";
import IconAddCart from "../assets/imgs/svg/icon_add_cart.svg";
import IconCartUnavailable from "../assets/imgs/svg/icon-cart-unavailable.svg";
import useCart from "@/hooks/useCart";
import { FC, useMemo } from "react";

type Props = {
  productVariation: ProductVariationWithProductResponse;
  board_id?: number;
};

const CircleCartBtn: FC<Props> = ({ productVariation, board_id }) => {
  const { hasSameItem: checkHasSameItem } = useCart();
  const hasSameItem = useMemo(
    () => checkHasSameItem(productVariation),
    [checkHasSameItem, productVariation],
  );

  return (
    <CartBtnWrapper
      productVariation={productVariation}
      product={productVariation.product}
      disabled={hasSameItem}
      board_id={board_id}
      btnView={
        <span
          className={[
            "flex items-center justify-center w-[34px] h-[34px] rounded-full flex-shrink-0",
            hasSameItem ? "bg-gray-200 opacity-25" : "bg-yellow-500",
          ].join(" ")}
        >
          <IconAddCart width={20} height={20} />
        </span>
      }
      unavailableView={
        <span
          className={
            "flex items-center justify-center w-[34px] h-[34px] rounded-full flex-shrink-0 bg-gray-200 opacity-25"
          }
        >
          <IconCartUnavailable width={20} height={20} />
        </span>
      }
    />
  );
};

export default CircleCartBtn;
