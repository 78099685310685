import { FC, PropsWithChildren } from "react";
import { formatSearchCount } from "@/utils/formatSearchCount";

interface Props {
  amount?: number;
}

const SearchAmountBar: FC<PropsWithChildren<Props>> = ({
  amount,
  children,
}): JSX.Element => {
  return (
    <div className="space-y-12 laptop:space-y-20">
      <div className="border-b border-solid border-primary flex items-center justify-between laptop:space-between gap-12">
        <div className="flex flex-row items-center">{children}</div>

        {amount && <p className="text-sm">{formatSearchCount(amount)}件</p>}
      </div>
    </div>
  );
};

export default SearchAmountBar;
