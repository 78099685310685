import {
  ListItemKind,
  ProductKind,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import useDevice from "@/hooks/useDevice";
import { LikeMutateHandler } from "@/features/like/hooks/useLike";
import useLikeToProduct from "@/features/like/hooks/useLikeToProduct";
import Link from "next/link";
import useVariation from "@/features/product_variation/hooks/useVariation";
import useProductVariation from "@/features/product_variation/hooks/useProductVariation";
import clsx from "clsx";
import Image from "next/image";
import Maybe from "@/components/Maybe";
import CircleCartBtn from "@/components/CircleCartBtn";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import MinimumLikeBtn from "@/features/like/components/MinimumLikeBtn";
import MinimumAddListBtn from "@/features/list/components/MinimumAddListBtn";
import ProductVariationPrice from "@/features/product_variation/components/ProductVariationPrice";
import SampleItemTSSDeliveryLabel from "@/features/product_variation/components/SampleItemTSSDeliveryLabel";
import { FC, useMemo } from "react";
import ModalPinnedPosts from "@/features/product_variation/components/ProductVariationItem/components/ModalPinnedPosts";
import useDisclosure from "@/hooks/useDisclosure";
import IconMapsHomeWork from "../../../../assets/imgs/svg/icon-maps_home_work.svg";
import IconArrowRight from "../../../../assets/imgs/svg/icon-arrow-right.svg";
import { theme } from "tailwind.config";

type Props = {
  productVariation: ProductVariationWithProductResponse;
  clickHandler?(productVariation: ProductVariationWithProductResponse): void;
  mutate?: LikeMutateHandler<ProductVariationWithProductResponse>;
  displaySize?: boolean;
  board_id?: number;
};

const ProductVariationItem: FC<Props> = ({
  productVariation,
  clickHandler,
  mutate,
  displaySize = false,
  board_id,
}) => {
  const { isSampleAvailable, isIndirectAvailable } =
    useProductVariation(productVariation);
  const { isPc } = useDevice();

  const { likeHandler } = useLikeToProduct<ProductVariationWithProductResponse>(
    productVariation,
    productVariation.product.kind,
    mutate,
    productVariation.product.maker_id,
  );

  const { displaySize: size, hasSizeInfo } = useVariation(productVariation);

  const showPrice = useMemo(
    () =>
      // メーカーと契約済みでかつ販売中の場合
      productVariation.product.maker.is_contracted &&
      !productVariation.is_discontinued,
    [productVariation],
  );

  const { isOpen, openHandler, closeHandler } = useDisclosure();

  return (
    <>
      <article className="relative flex flex-col gap-8 group">
        <div className={clsx("relative aspect-square")}>
          <LinkAsModal
            entityId={productVariation.product_id}
            entityType={ModalEntityType.Product}
            as={`/products/${productVariation.product.id}`}
            params={{ v_id: productVariation.id.toString() }}
            className="absolute inset-0 w-full h-full"
          >
            <a onClick={() => clickHandler && clickHandler(productVariation)}>
              <Image
                className="object-contain object-center bg-white"
                alt={productVariation.full_name}
                src={productVariation.upload_image.urls.small}
                unoptimized
                fill
              />
            </a>
          </LinkAsModal>

          <div className="absolute inset-x-0 p-8 flex items-center justify-between gap-8">
            <div>
              <Maybe condition={isIndirectAvailable}>
                <SampleItemTSSDeliveryLabel />
              </Maybe>
            </div>

            <Maybe condition={isPc}>
              <div className="opacity-0 group-hover:opacity-100 transition duration-200 flex items-center gap-4">
                <MinimumLikeBtn
                  hasLiked={productVariation.has_liked}
                  onClick={likeHandler}
                />
                <MinimumAddListBtn
                  selectedItem={{
                    ...productVariation,
                    maker_id: productVariation.product.maker.id,
                  }}
                  kind={
                    productVariation.product.kind ===
                    ProductKind.BUILDING_MATERIAL
                      ? ListItemKind.BUILDING_MATERIAL
                      : ListItemKind.INTERIOR
                  }
                />
              </div>
            </Maybe>
          </div>
        </div>

        <div className={clsx("relative flex flex-col justify-between gap-8")}>
          <div className="flex flex-col gap-8">
            <div className="flex justify-between items-start gap-4">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                  <Link
                    className="text-xs text-secondary line-clamp-1 break-all"
                    href={`/makers/${productVariation.product.maker.id}`}
                    prefetch={false}
                  >
                    {productVariation.product.maker.name}
                  </Link>

                  <h1 className="text-xs font-bold line-clamp-2 h-[36px]">
                    <LinkAsModal
                      entityId={productVariation.product_id}
                      entityType={ModalEntityType.Product}
                      as={`/products/${productVariation.product.id}`}
                      params={{ v_id: productVariation.id.toString() }}
                    >
                      <a
                        onClick={() =>
                          clickHandler && clickHandler(productVariation)
                        }
                      >
                        {productVariation.full_name}
                      </a>
                    </LinkAsModal>
                  </h1>
                </div>

                <div className="text-xs flex flex-col gap-4">
                  {showPrice && (
                    <div className="line-clamp-1 h-[18px]">
                      <ProductVariationPrice
                        productVariation={productVariation}
                      />
                    </div>
                  )}
                  <Maybe condition={displaySize && hasSizeInfo}>
                    <div className="line-clamp-1 h-[18px]">
                      <div>{size}(mm)</div>
                    </div>
                  </Maybe>
                </div>
              </div>

              <Maybe condition={isSampleAvailable}>
                <div className="py-4 flex-shrink-0">
                  <CircleCartBtn
                    productVariation={productVariation}
                    board_id={board_id}
                  />
                </div>
              </Maybe>
            </div>
          </div>

          <Maybe condition={productVariation.posts_count > 0}>
            <hr />

            <button
              className="flex items-center justify-between"
              type="button"
              onClick={openHandler}
              aria-label={`${productVariation.full_name}の利用事例を見る`}
            >
              <span className="inline-flex items-center gap-8">
                <IconMapsHomeWork
                  width={16}
                  height={16}
                  fill={theme.colors.gray[600]}
                />
                <span className="text-xs">利用事例</span>
              </span>
              <span className="inline-flex items-center gap-4">
                <span className="text-secondary text-sm">
                  {productVariation.posts_count}件
                </span>
                <IconArrowRight
                  width={16}
                  height={16}
                  fill={theme.colors.gray[600]}
                />
              </span>
            </button>
          </Maybe>
        </div>
      </article>

      <ModalPinnedPosts
        productVariation={productVariation}
        isOpen={isOpen}
        closeHandler={closeHandler}
      />
    </>
  );
};

export default ProductVariationItem;
