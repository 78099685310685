import { FC } from "react";
import Modal from "@/components/Modal";
import client from "@/utils/api/client";
import PostItemList from "@/features/post/components/PostItemList";
import PostItem from "@/features/post/components/PostItem";
import usePagination from "@/hooks/usePagination";
import {
  PostResponse,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import InfiniteScroll from "react-infinite-scroll-component";
import Circular from "@/components/Circular";
import Image from "next/image";
import clsx from "clsx";
import Link from "next/link";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import SearchAmountBar from "@/components/SearchAmountBar";

interface Props {
  isOpen: boolean;
  closeHandler: () => void;
  productVariation: ProductVariationWithProductResponse;
}

const ModalPinnedPosts: FC<Props> = ({
  isOpen,
  closeHandler,
  productVariation,
}) => {
  const {
    data: posts,
    dataLength,
    next,
    hasNext,
    totalCount,
    updateItem,
  } = usePagination<PostResponse>(
    isOpen ? `/product_variations/${productVariation.id}/posts` : null,
    20,
    async ({ limit, page }) => {
      const { data } = await client.productVariationsIdPostsGet({
        id: productVariation.id,
        limit,
        page,
      });

      return data;
    },
    { waitForAuth: true },
  );

  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      <div
        className={clsx(
          "flex flex-col p-8 pt-0 gap-0",
          "laptop:p-12 laptop:pt-0",
        )}
      >
        <header className="sticky top-0 flex gap-12 py-8 bg-white z-[1]">
          <LinkAsModal
            entityId={productVariation.product_id}
            entityType={ModalEntityType.Product}
            as={`/products/${productVariation.product.id}`}
            params={{ v_id: productVariation.id.toString() }}
          >
            <div
              className={clsx("relative", "w-[64px] h-[64px] flex-shrink-0")}
            >
              <Image
                className="object-contain object-center border border-primary"
                src={productVariation.upload_image.urls.small}
                alt={productVariation.full_name}
                unoptimized
                fill
              />
            </div>
          </LinkAsModal>
          <div>
            <Link
              className="text-secondary text-sm"
              href={`/makers/${productVariation.product.maker.id}`}
            >
              {productVariation.product.maker.name}
            </Link>
            <h2>
              <LinkAsModal
                entityId={productVariation.product_id}
                entityType={ModalEntityType.Product}
                as={`/products/${productVariation.product.id}`}
                params={{ v_id: productVariation.id.toString() }}
                className="font-bold"
              >
                {productVariation.full_name}
              </LinkAsModal>
            </h2>
          </div>
        </header>

        <div className="flex flex-col gap-12">
          <SearchAmountBar amount={totalCount} />
          <InfiniteScroll
            style={{ overflow: "visible" }}
            dataLength={dataLength}
            next={next}
            hasMore={hasNext}
            loader={<Circular />}
          >
            <PostItemList
              posts={posts || []}
              onRender={(post) => {
                return (
                  <PostItem
                    key={post.id}
                    post={post}
                    mutate={(updated) => updateItem(updated, false)}
                  />
                );
              }}
            />
          </InfiniteScroll>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPinnedPosts;
