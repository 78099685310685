import formatNumber from "@/utils/formatNumber";
import { ProductVariationWithCatalogPagesResponse } from "@/web-client/api";

import { FC, useMemo } from "react";

interface Props {
  productVariation: ProductVariationWithCatalogPagesResponse;
}

const suffix = "[税抜]";

const ProductVariationPrice: FC<Props> = ({
  productVariation,
}): JSX.Element => {
  const unitElem = useMemo<JSX.Element>(() => {
    if (!productVariation.unit) {
      return null;
    }

    return <span>({productVariation.unit})</span>;
  }, [productVariation.unit]);

  const minPrice = useMemo<number>(
    () => productVariation.min_price || 0,
    [productVariation.min_price],
  );

  // 最低価格が0円かつ最高価格が未入力もしくは0の場合
  if (minPrice === 0) return null;

  // 最大価格と最低価格が同じ場合
  if (minPrice === productVariation.max_price)
    return (
      <p>
        ￥{formatNumber(minPrice)}
        {unitElem}
        <span>{suffix}</span>
      </p>
    );

  // 最高価格が空、もしくは0
  if (!productVariation.max_price)
    return (
      <p>
        ￥{formatNumber(minPrice)} ~ {unitElem}
        <span>{suffix}</span>
      </p>
    );

  return (
    <p>
      ￥{formatNumber(minPrice)} ~ {formatNumber(productVariation.max_price)}
      {unitElem}
      <span>{suffix}</span>
    </p>
  );
};

export default ProductVariationPrice;
